<template>
  <CustomModal :name="name" :classes="['add-user-modal']" @before-close="reset">
    <div class="add-user-modal__close">
      <Icon
        name="close"
        is-clickable
        color="secondary-500"
        @click="$modal.hide(name)"
      />
    </div>
    <template v-slot:header>
      <p class="add-user-modal__title">Add user</p>
    </template>
    <Form v-slot="{ invalid, changed }">
      <FormItem rules="required|email" class="mb-24" v-slot="{ isError }">
        <Input
          v-model="form.email"
          label="User's email"
          placeholder="Email"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" class="mb-48" v-slot="{ isError }">
        <Select
          v-model="form.role"
          :options="roleOptions"
          label="Role"
          placeholder="Role"
          :is-error="isError"
        />
      </FormItem>
      <Button
        is-block
        :is-loading="isSubmitting"
        :is-disabled="invalid || !changed"
        @click="handleSubmit"
      >
        Add user
      </Button>
    </Form>
  </CustomModal>
</template>

<script>
import { roleOptions } from "@/helpers/mocks";

export default {
  name: "AddUserModal",
  props: {
    name: {
      type: String,
      default: "addUserModal",
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: "",
        role: "",
      },
      roleOptions,
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("submit", this.form);
    },
    reset() {
      this.form = {
        email: "",
        role: "",
      };
    },
  },
};
</script>

<style lang="scss">
.add-user-modal {
  padding: 40px 24px;
  overflow: visible;
}
</style>

<style lang="scss" scoped>
.add-user-modal {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $secondary-500;
    margin-bottom: 24px;
  }
}
</style>
