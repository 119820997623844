<template>
  <div class="user-card">
    <div class="user-card__row">
      <span class="user-card__name">{{ name || "-" }}</span>
      <span v-if="lastSeenAt" class="user-card__last-login">
        last seen
        <span class="user-card__last-login__time">
          {{ lastSeenAt }}
        </span>
      </span>
    </div>
    <div class="user-card__row">
      <span :class="['user-card__role', `m-${role}`]">
        {{ `${roleName(role) || "-"} ${isYou ? "(You)" : ""}` }}
      </span>
      <span v-if="email" class="user-card__email">
        {{ email }}
      </span>
    </div>
  </div>
</template>

<script>
import { ROLE_NAMES_MAP } from "@/helpers/const";

export default {
  name: "UserCard",
  props: {
    name: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    lastSeenAt: {
      type: String,
      default: "",
    },
    isYou: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    roleName(role) {
      return ROLE_NAMES_MAP[role];
    },
  },
};
</script>

<style lang="scss" scoped>
.user-card {
  background-color: $white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;
  cursor: pointer;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $secondary-500;
  }

  &__role {
    font-size: 14px;
    line-height: 24px;

    &.m-owner {
      color: $success;
    }
    &.m-manager {
      color: $primary;
    }
    &.m-staff {
      color: $secondary-500;
    }
  }

  &__email {
    font-size: 14px;
    line-height: 24px;
    color: $secondary-400;
  }

  &__last-login {
    font-size: 14px;
    line-height: 24px;
    color: $secondary-400;

    &__time {
      color: $secondary-500;
    }
  }
}
</style>
