<template>
  <div class="users-page">
    <Guard permission="users.create" v-slot="{ isAvailable }">
      <IconButton
        v-if="isAvailable"
        icon="plus"
        is-raised
        class="mb-32"
        @click="openAdd"
      >
        Add user
      </IconButton>
    </Guard>
    <div class="users-page__title mb-32">
      <Title>Users</Title>
      <Icon
        name="sort"
        :color="isFiltersShow ? 'primary' : 'secondary-500'"
        is-clickable
        @click="isFiltersShow = !isFiltersShow"
      />
    </div>
    <div v-if="isFiltersShow" class="grid-2 mb-32">
      <Select v-model="roleFilter" :options="roleOptions" label="Role" />
      <Select v-model="sort" :options="sortOptions" label="Sort" />
    </div>
    <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
    <div v-else class="grid-2">
      <UserCard
        v-for="user in users"
        :key="user.id"
        :name="user.displayName"
        :role="user.role"
        :email="user.email"
        :last-seen-at="getLastSeenAt(user)"
        :isYou="isYou(user)"
        @click.native="goToDetailed(user)"
      />
    </div>
    <Guard permission="users.create" v-slot="{ isAvailable }">
      <AddUserModal
        v-if="isAvailable"
        :name="addModalName"
        :is-submitting="isAdding"
        @submit="handleAddUser"
      />
    </Guard>
  </div>
</template>

<script>
import UserCard from "@/components/users/UserCard";
import { mapActions, mapMutations, mapState } from "vuex";
import { auth } from "@/plugins/firebase";
import AddUserModal from "@/components/users/AddUserModal";
import UsersService from "@/api/services/UsersService";
import dialog from "@/plugins/dialog";
import { UsersSortEnum } from "@/helpers/enums";
import moment from "moment";
import { getTimeDifference } from "@/helpers/utils";
import { roleOptions } from "@/helpers/mocks";
import axios from "axios";
import Guard from "@/components/common/Guard";

export default {
  name: "UsersPage",
  components: { Guard, AddUserModal, UserCard },
  data() {
    return {
      addModalName: "userAddModal",
      isLoading: false,
      isAdding: false,
      isFiltersShow: false,
      roleOptions: [
        {
          value: null,
          name: "All",
        },
        ...roleOptions,
      ],
      sortOptions: [
        {
          value: UsersSortEnum.AZ,
          name: "A - Z",
        },
        {
          value: UsersSortEnum.ZA,
          name: "Z - A",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.users.list,
      roleFilterState: (state) => state.users.roleFilter,
      sortState: (state) => state.users.sort,
    }),
    roleFilter: {
      get() {
        return this.roleFilterState;
      },
      set(newValue) {
        this.SET_ROLE_FILTER(newValue);
        this.refetchUsers();
      },
    },
    sort: {
      get() {
        return this.sortState;
      },
      set(newValue) {
        this.SET_SORT(newValue);
        this.refetchUsers();
      },
    },
  },
  created() {
    this.refetchUsers();
  },
  methods: {
    ...mapActions({
      fetchUsers: "users/fetchUsers",
    }),
    ...mapMutations({
      SET_ROLE_FILTER: "users/SET_ROLE_FILTER",
      SET_SORT: "users/SET_SORT",
    }),
    async refetchUsers() {
      let isCanceled = false;
      try {
        this.isLoading = true;
        await this.fetchUsers();
      } catch (e) {
        isCanceled = axios.isCancel(e);
      } finally {
        if (!isCanceled) {
          this.isLoading = false;
        }
      }
    },
    isYou(user) {
      return auth.currentUser?.uid === user.id;
    },
    getLastSeenAt(user) {
      return user.lastSeenTimestamp
        ? getTimeDifference({
            current: moment.utc().valueOf(),
            previous: user.lastSeenTimestamp,
            recentMinutes: 60,
            recentString: "recently",
          })
        : "never";
    },
    openAdd() {
      this.$modal.show(this.addModalName);
    },
    goToDetailed(user) {
      this.$router.push({
        name: "EditUser",
        params: {
          id: user.id,
        },
      });
    },
    async handleAddUser(data) {
      try {
        this.isAdding = true;
        await UsersService.addUser(data);
        await this.fetchUsers();
        this.$modal.hide(this.addModalName);
        await dialog.alert({
          title: "User successfully added",
          okText: "Done",
        });
      } finally {
        this.isAdding = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.users-page {
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}
</style>
